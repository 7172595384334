// src/pages/dashboard/dashboard-b2b.page.tsx
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import {
  Button,
  PageHeader,
  LineChart,
  EmptyState,
  SelectOption,
  StatCard,
  StatCardProps,
  Select,
  BarChart,
  Card,
  formatCurrency,
  ProjectListSection,
  useApi,
  Project,
  UsersStats,
} from "@fraktion/core-frontend";

export const DashboardPage: React.FC = () => {
  const { t } = useTranslation(["dashboard"]);
  const apiClient = useApi();
  const [projects, setProjects] = useState<Project[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const [startDate, setStartDate] = useState(new Date("2023-09-27"));
  const [endDate, setEndDate] = useState(new Date("2024-09-27"));
  const [selectedCampaign, setSelectedCampaign] = useState<string[]>(["all"]);
  const [selectedCampaignLine, setSelectedCampaignLine] = useState<string[]>([
    "all",
  ]);
  const [selectedYear, setSelectedYear] = useState<string>("2023");

  const [usersStats, setUsersStats] = useState<UsersStats | null>(null);
  const [, setIsStatsLoading] = useState(true);
  const [, setStatsError] = useState<string | null>(null);

  const amountCollectedChartData = {
    labels: [
      "Sep",
      "Oct",
      "Nov",
      "Dec",
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
    ],
    datasets: [
      {
        label: t("dashboard.campaignOne"),
        data: [100, 220, 180, 200, 280, 350, 370, 320, 270, 160, 100, 140, 300],
        borderColor: "#6E6E6E",
        backgroundColor: "#1B59F8",
        gradientStops: {
          start: "rgba(255, 215, 0, 1)",
          middle: "rgba(255, 215, 0, 1)",
          end: "rgba(255, 215, 0, 0)",
        },
        fill: true,
        order: 2,
      },
      {
        label: t("dashboard.campaignTwo"),
        data: [50, 100, 80, 180, 220, 280, 300, 240, 220, 180, 160, 140, 200],
        borderColor: "#FF7D00",
        backgroundColor: "#11A2B6",
        gradientStops: {
          start: "rgba(255, 125, 0, 1)",
          middle: "rgba(255, 125, 0, 0.1)",
          end: "rgba(255, 125, 0, 0)",
        },
        fill: true,
        order: 1,
      },
    ],
  };

  const campaignOptions: SelectOption[] = [
    { value: "all", label: t("dashboard.all") },
    { value: "campaign1", label: t("dashboard.campaignOne") },
    { value: "campaign2", label: t("dashboard.campaignTwo") },
  ];

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "bottom" as const,
        align: "start" as const,
        labels: {
          usePointStyle: true,
          pointStyle: "rectRounded",
          padding: 20,
          font: {
            size: 12,
          },
          boxWidth: 16,
          boxHeight: 16,
        },
      },
      title: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          color: "rgba(0, 0, 0, 0.1)",
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
    elements: {
      line: {
        tension: 0.4,
      },
      point: {
        radius: 0,
      },
    },
  };

  const distributorData = {
    labels: [
      "Jan",
      "Fev",
      "Mar",
      "Avr",
      "Jun",
      "Jui",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "CGP 2",
        data: [800, 900, 700, 600, 800, 900, 1000, 900, 800, 700],
        backgroundColor: "#FFD700", // Solid secondary color
        hoverBackgroundColor: "#FFE44D", // Lighter shade for hover
        order: 1, // Lower order appears in front
      },
      {
        label: "Total",
        data: [1232, 1232, 1132, 1032, 1132, 1232, 1232, 1132, 1132, 1232],
        backgroundColor: "rgba(0, 0, 0, 0.1)",
        hoverBackgroundColor: "rgba(0, 0, 0, 0.2)",
        order: 2, // Higher order appears in back
      },
    ],
  };

  const distributorOptions = {
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: false,
      },
    },
  };

  useEffect(() => {
    const fetchProjects = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const projectsResponse = await apiClient.projectsApi.projects({
          limit: 5,
        });
        setProjects(projectsResponse);
      } catch (error) {
        console.error("Error fetching projects:", error);
        setError("Failed to fetch projects. Please try again later.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchProjects();
  }, [apiClient]);

  useEffect(() => {
    const fetchUsersStats = async () => {
      setIsStatsLoading(true);
      setStatsError(null);
      try {
        const stats = await apiClient.usersApi.usersStats();
        setUsersStats(stats);
      } catch (error) {
        console.error("Error fetching users stats:", error);
        setStatsError("Failed to fetch users statistics");
      } finally {
        setIsStatsLoading(false);
      }
    };

    fetchUsersStats();
  }, [apiClient]);

  const kpiData: StatCardProps[] = [
    {
      title: t("dashboard.registeredUsers"),
      value: usersStats?.total.toString() ?? "0",
      icon: "user",
      linkText: t("common:common.seeMore"),
      description: t("dashboard.registeredUsersDescription"),
      link: "/users",
    },
    {
      title: t("dashboard.kycVerified"),
      value: usersStats?.kyced.toString() ?? "0",
      icon: "check",
      linkText: t("common:common.seeMore"),
      description: t("dashboard.kycVerifiedDescription"),
      link: "/users",
    },
    {
      title: t("dashboard.investors"),
      value: usersStats?.active.toString() ?? "0",
      icon: "wallet",
      linkText: t("common:common.seeMore"),
      description: t("dashboard.investorsDescription"),
      link: "/users",
    },
  ];

  const goToCreateProject = () => navigate("/projects/create");
  const goToProjectsList = () => navigate("/projects");

  return (
    <div className="space-y-8">
      <PageHeader title={t("dashboard.dashboardTitle")} />
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {kpiData.map((stat, index) => (
          <StatCard key={index} {...stat} />
        ))}
      </div>
      <LineChart
        title={t("dashboard.totalAmountCollected")}
        mainValue={1150042}
        changeValue={3.5}
        isPositiveChange={true}
        startDate={startDate}
        endDate={endDate}
        onStartDateChange={setStartDate}
        onEndDateChange={setEndDate}
        selectedFilter={selectedCampaignLine}
        filterOptions={campaignOptions}
        onFilterChange={setSelectedCampaignLine}
        chartData={amountCollectedChartData}
        chartOptions={chartOptions}
        renderEmptyState={() => (
          <EmptyState
            icon="coins"
            title={t("dashboard.noAmountCollectedTitle")}
            description={t("dashboard.noAmountCollectedDescription")}
            primaryAction={{
              label: t("dashboard.addProject"),
              onClick: () => {
                navigate("/projects/create");
              },
              icon: "plus",
            }}
          />
        )}
      />
      <ProjectListSection
        projects={projects}
        isLoading={isLoading}
        error={error}
        onCreateProject={goToCreateProject}
        onViewMore={goToProjectsList}
      />
      <Card header={t("dashboard.distributorPerformance")} size="sm">
        {!distributorData.datasets.some(
          (dataset) => dataset.data.length > 0
        ) ? (
          <EmptyState
            icon="link"
            title={t("dashboard.noDistributorData")}
            description={t("dashboard.noDistributorDataDescription")}
          />
        ) : (
          <>
            <div className="flex justify-between items-center">
              <div className="flex flex-col">
                <div className="flex items-center mt-2">
                  <span className="text-3xl font-semibold text-text">
                    {" "}
                    +
                    {formatCurrency(15042, {
                      currency: "EUR",
                      symbolPosition: "after",
                      thousandsSeparator: " ",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                  </span>
                </div>
              </div>
              <Select
                options={[
                  { value: "2023", label: t("dashboard.currentYear") },
                  { value: "2022", label: t("dashboard.previousYear") },
                  { value: "2021", label: t("dashboard.twoYearsAgo") },
                ]}
                value={selectedYear}
                onChange={(value) => setSelectedYear(value as string)}
                className="text-gray-900 dark:text-gray-100"
              />
            </div>
            <div className="flex flex-col sm:flex-row sm:items-center gap-2 mb-6">
              <span className="text-gray-600 dark:text-gray-400 whitespace-nowrap">
                {t("dashboard.filterByCategory")}
              </span>
              <Select
                options={[
                  { value: "all", label: t("common:common.all") },
                  { value: "category1", label: t("dashboard.category1") },
                  { value: "category2", label: t("dashboard.category2") },
                ]}
                isMulti
                value={selectedCampaign}
                onChange={(value) => setSelectedCampaign(value as string[])} // Handle as array
                className="max-w-full"
              />
            </div>
            <BarChart data={distributorData} options={distributorOptions} />
            <div className="flex justify-center mt-2">
              <Button
                variant="link"
                color="primary"
                size="sm"
                icon="chevron-right"
                iconPosition="right"
                className="text-text"
              >
                {t("common:common.seeMore")}
              </Button>
            </div>
          </>
        )}
      </Card>
    </div>
  );
};

export default DashboardPage;
